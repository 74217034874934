<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
    <pf-image
        src="assets/img/69844430.jpeg"
        alt="GeOsm Logo"
        class="brand-image"
        height="33"
        width="33"
        rounded
        style="opacity: 0.8"
    ></pf-image>
    <span class="brand-text font-weight-light">GeOsm</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <pf-image
                [src]=" user.profile_picture"
                fallback-src="assets/img/default-profile.png"
                class="img-circle"
                alt="User Image"
                width="34"
                height="34"
                rounded
            ></pf-image>
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div>
    </div>

    <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
            ></app-menu-item>
        </ul>
    </nav>
</div>
