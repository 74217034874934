import { Thematique } from '@/interfaces/thematiques';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-view.thematique',
    templateUrl: './view.thematique.component.html',
    styleUrls: ['./view.thematique.component.scss']
})
export class ViewThematiqueComponent implements OnInit {
    thematique: Thematique;
    environment;

    data: {thematique:Thematique,site:string} = {
      thematique: undefined,
      site: ''
    };
    routeState: any;
    color: string;

    constructor(public router: Router) {
        if (this.router.getCurrentNavigation()?.extras.state) {
            this.routeState = this.router.getCurrentNavigation()?.extras.state;
            if (this.routeState) {
                this.data.thematique = this.routeState.thematique
                    ? JSON.parse(this.routeState.thematique)
                  : '';
              this.thematique = this.data.thematique
                this.data.site = this.routeState.site
                    ? this.routeState.site
                    : '';
            }
        }
    }
    ngOnInit(): void {
        this.environment = environment;
    }
}
