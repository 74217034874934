import { Couche } from '@/interfaces/couches';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-view.couche',
  templateUrl: './view.couche.component.html',
  styleUrls: ['./view.couche.component.scss']
})
export class ViewCoucheComponent implements OnInit {
  couche: Couche;
  environment;

  data: { couche: Couche; site: string } = {
    couche: undefined,
    site: ''
  };
  routeState: any;

  constructor(public router: Router) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
      if (this.routeState) {
        this.data.couche = this.routeState.couche ? JSON.parse(this.routeState.couche) : '';
        this.couche = this.data.couche;
        this.data.site = this.routeState.site ? this.routeState.site : '';
      }
    }
  }
  ngOnInit(): void {
    this.environment = environment;
  }
}
