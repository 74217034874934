import { Couche } from '@/interfaces/couches';
import { SousThematique } from '@/interfaces/sousthematiques';
import { Thematique } from '@/interfaces/thematiques';
import {Component, OnInit} from '@angular/core';
import { CouchesService } from '@services/couches.service';
import { SousthematiquesService } from '@services/sousthematiques.service';
import { ThematiquesService } from '@services/thematiques.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    thematiques: Thematique[];
    sousThematiques: SousThematique[]
    couches: Couche[]

    constructor(
        public thematiquesService: ThematiquesService,
        public sousthematiquesService: SousthematiquesService,
        public couchesService:CouchesService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.thematiquesService.loadThematiques().then(
            (response) => {
                this.thematiques =
                    this.thematiquesService.getThematiques().data.thematiques;
                
            },
            (error) => {
                this.toastr.error('Echec lors du chargement des thématiques');
            }
        );

        this.sousthematiquesService.loadSousThematiques().then(
            (response) => {
                this.sousThematiques =
                    this.sousthematiquesService.getSousThematiques().data.sous_thematiques;
            },
            (error) => {
                this.toastr.error('Echec lors du chargement des Sous Thématiques');
            }
        );

        this.couchesService.loadCouches().then(
          response => {
            this.couches = this.couchesService.getCouches().data.couches;
          },
          error => {
            this.toastr.error('Echec lors du chargement des couches');
          }
        );
    }
}
