import { User } from '@/interfaces/auth';
import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { AuthService } from '@services/auth.service';
import {from, Observable} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
/**
 * A user is not connect ?
 */
export class NonAuthGuard implements CanActivate {
    constructor(private router: Router,public authService:AuthService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise((resolve) => {
            this.authService.getUserConnect()
                .pipe()
                .subscribe(
                    (user: User) => {
                        this.router.navigate(['/']);
                        resolve(false);
                    },
                    (err) => {
                        resolve(true);
                    }
                );
        });
    }
}
