import { User } from '@/interfaces/auth';
import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AuthService} from '@services/auth.service';
import { environment } from 'environments/environment';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user:User;
    public menu = MENU;
    environment

    constructor(
        public authService: AuthService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.environment = environment
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.authService.getUser();
    }
}

export const MENU = [
  {
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    path: ['/']
  },

  {
    name: 'GeOsm Features',
    iconClasses: 'fas fa-folder',
    children: [
      {
        name: 'Thematiques',
        iconClasses: 'far fa-address-book',
        path: ['/thematiques']
      },
      {
        name: 'Sous-Thematiques',
        iconClasses: 'far fa-address-book',
        path: ['/sousthematiques']
      },
      {
        name: 'Couches',
        iconClasses: 'far fa-address-book',
        path: ['/couches']
      },
      
    ]
  }
];
