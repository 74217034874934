import { User } from '@/interfaces/auth';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '@services/auth.service';
import { environment } from 'environments/environment';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user: User;
    environment;

    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.environment = environment;
        this.user = this.authService.getUser();
    }

    logout() {
        this.authService
            .logout()
            .then((response: {error: boolean; msg?: string}) => {
                if (response.error) {
                } else {
                    this.router.navigate(['/login']);
                }
            });
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
