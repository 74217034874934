import {User} from '@/interfaces/auth';
import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/**
 * A user is connect ?
 */
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise((resolve) => {
            this.authService.getUserConnect()
                .pipe()
                .subscribe(
                    (user: User) => {
                        resolve(true);
                    },
                    (err) => {
                        this.router.navigate(['login']);
                        resolve(false);
                    }
                );
        });
    }
}
