import { Thematique } from '@/interfaces/thematiques';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import { ThematiquesService } from '@services/thematiques.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import {ADTSettings} from 'angular-datatables/src/models/settings';
import { Router } from '@angular/router';

@Component({
    selector: 'app-thematiques',
    templateUrl: './thematiques.component.html',
    styleUrls: ['./thematiques.component.scss']
})
export class ThematiquesComponent implements OnInit {
    thematiques: Thematique[];
    environment;
    dtOptions: any = {};

    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();

    constructor(
        public thematiquesService: ThematiquesService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.environment = environment;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 5,
            processing: true,
            language: {
                processing: 'Traitement...',
                search: 'Rechercher:',
                lengthMenu: 'Voir _MENU_ &eacute;l&eacute;ments',
                info: 'Affichage de _START_ à _END_ sur _TOTAL_ éléments',
                infoEmpty: "Il n'y a pas d'éléments.",

                emptyTable: 'Aucune donnée disponible dans le tableau',
                paginate: {
                    first: 'Premier',
                    previous: 'Précédent',
                    next: 'Suivant',
                    last: 'Dernier'
                }
            },
            dom: 'Bfrtip',
            buttons: ['copy', 'print', 'excel']
        };

        this.thematiquesService.loadThematiques().then(
            (response) => {
                this.thematiques =
                    this.thematiquesService.getThematiques().data.thematiques;

                this.dtTrigger.next(this.dtOptions);
            },
            (error) => {
                this.toastr.error('Echec lors du chargement des thématiques');
            }
        );
    }

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();

            this.thematiquesService.loadThematiques().then(
                (response) => {
                    this.thematiques =
                        this.thematiquesService.getThematiques().data.thematiques;

                    this.dtTrigger.next(this.dtOptions);
                },
                (error) => {
                    this.toastr.error(
                        'Echec lors du chargement des thématiques'
                    );
                }
            );
        });
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    createThematique() {
        this.router.navigate(['/thematiques/create']);
    }

    viewThematique(thematique: Thematique) {
        this.router.navigate(['/thematiques/view'], {
            state: {
                thematique: JSON.stringify(thematique),
                site: 'geosm.org'
            }
        });
    }

    editThematique(thematique: Thematique) {
        this.router.navigate(['/thematiques/edit'], {
            state: {
                thematique: JSON.stringify(thematique),
                site: 'geosm.org'
            }
        });
    }

    deleteThematique(id: number) {
        this.thematiquesService.deleteThematique(id).then(
            (response) => {
                this.toastr.success('Suppression reussie');
                this.rerender();
            },
            (error) => {
                this.toastr.error('Erreur lors de la suppression');
            }
        );
    }
}
