<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://geosm.org"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        geosm.org</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span>
