<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>GeOsm</b></a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Connectez vous pour demarrez votre session</p>

        <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                
                <!-- /.col -->
                <div class="col-5">
                    <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                    >
                        Connexion
                    </pf-button>
                </div>
                <!-- /.col -->
            </div>
        </form>


       
    </div>
    <!-- /.login-card-body -->
</div>
