import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { ThematiquesComponent } from '@pages/thematiques/thematiques.component';
import { CreateThematiqueComponent } from '@pages/thematiques/create.thematique/create.thematique.component';
import { ViewThematiqueComponent } from '@pages/thematiques/view.thematique/view.thematique.component';
import { EditThematiqueComponent } from '@pages/thematiques/edit.thematique/edit.thematique.component';
import { SousthematiquesComponent } from '@pages/sousthematiques/sousthematiques.component';
import { CreateSousthematiqueComponent } from '@pages/sousthematiques/create.sousthematique/create.sousthematique.component';
import { ViewSousthematiqueComponent } from '@pages/sousthematiques/view.sousthematique/view.sousthematique.component';
import { EditSousthematiqueComponent } from '@pages/sousthematiques/edit.sousthematique/edit.sousthematique.component';
import { CouchesComponent } from '@pages/couches/couches.component';
import { ViewCoucheComponent } from '@pages/couches/view.couche/view.couche.component';
import { CreateCoucheComponent } from '@pages/couches/create.couche/create.couche.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'thematiques',
        component: ThematiquesComponent
      },
      {
        path: 'thematiques/create',
        component: CreateThematiqueComponent
      },
      {
        path: 'thematiques/view',
        component: ViewThematiqueComponent
      },
      {
        path: 'thematiques/edit',
        component: EditThematiqueComponent
      },
      {
        path: 'sousthematiques',
        component: SousthematiquesComponent
      },
      {
        path: 'sousthematiques/create',
        component: CreateSousthematiqueComponent
      },
      {
        path: 'sousthematiques/view',
        component: ViewSousthematiqueComponent
      },
      {
        path: 'sousthematiques/edit',
        component: EditSousthematiqueComponent
      },
      {
        path: 'couches',
        component: CouchesComponent
      },
      {
        path: 'couches/view',
        component: ViewCoucheComponent
      },
      {
        path: 'couches/create',
        component: CreateCoucheComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },

  { path: '**', redirectTo: '/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
