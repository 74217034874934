<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3 *ngIf="thematiques">{{thematiques.length}}</h3>
                        <h3 *ngIf="!thematiques"></h3>

                        <p>Thématiques</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a [routerLink]="['/thematiques']" class="small-box-footer"
                        >Parcourir <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3 *ngIf="sousThematiques">{{sousThematiques.length}}</h3>
                        <h3 *ngIf="!sousThematiques"></h3>

                        <p>Sous-Thematiques</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <a [routerLink]="['/sousthematiques']" class="small-box-footer"
                        >Parcourir <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        
                        <h3 *ngIf="couches">{{couches.length}}</h3>
                        <h3 *ngIf="!couches"></h3>

                        <p>Couches</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a [routerLink]="['/couches']" class="small-box-footer"
                        >Parcourir <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            
            <!-- ./col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
