<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profil</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                    <li class="breadcrumb-item active">Profil Utilisateur</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <pf-image
                                class="user-img"
                                [src]=" user.profile_picture"
                fallback-src="assets/img/default-profile.png"
                                alt="User profile picture"
                                width="100"
                                height="100"
                                rounded
                            ></pf-image>
                        </div>

                        <h3 class="profile-username text-center">
                            {{user.first_name + user.last_name}}
                        </h3>

                        <p class="text-muted text-center">{{user.titre}}</p>

                        <!--ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Followers</b>
                                <a class="float-right">1,402</a>
                            </li>
                            <li class="list-group-item">
                                <b>Following</b> <a class="float-right">543</a>
                            </li>
                            <li class="list-group-item">
                                <b>Friends</b> <a class="float-right">13,287</a>
                            </li>
                        </ul>

                        <a href="#" class="btn btn-primary btn-block"
                            ><b>Follow</b></a
                    -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- About Me Box -->
                
                <!-- /.card -->
            </div>
            <!-- /.col -->
           
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
