import { User } from '@/interfaces/auth';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    public user: User;
    environment;

    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.environment = environment;
        this.user = this.authService.getUser();
    }
}
