import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import { ThematiquesComponent } from './pages/thematiques/thematiques.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateThematiqueComponent } from './pages/thematiques/create.thematique/create.thematique.component';

import { ColorPickerModule } from 'primeng/colorpicker';
import { ViewThematiqueComponent } from './pages/thematiques/view.thematique/view.thematique.component';
import { EditThematiqueComponent } from './pages/thematiques/edit.thematique/edit.thematique.component';
import { SousthematiquesComponent } from './pages/sousthematiques/sousthematiques.component';
import { CreateSousthematiqueComponent } from './pages/sousthematiques/create.sousthematique/create.sousthematique.component';
import { ViewSousthematiqueComponent } from './pages/sousthematiques/view.sousthematique/view.sousthematique.component';
import { EditSousthematiqueComponent } from './pages/sousthematiques/edit.sousthematique/edit.sousthematique.component';
import { CouchesComponent } from './pages/couches/couches.component';
import { ViewCoucheComponent } from './pages/couches/view.couche/view.couche.component';
import { CreateCoucheComponent } from './pages/couches/create.couche/create.couche.component';

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        ThematiquesComponent,
        CreateThematiqueComponent,
        ViewThematiqueComponent,
        EditThematiqueComponent,
        SousthematiquesComponent,
        CreateSousthematiqueComponent,
        ViewSousthematiqueComponent,
        EditSousthematiqueComponent,
        CouchesComponent,
        ViewCoucheComponent,
        CreateCoucheComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
         ReactiveFormsModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DataTablesModule,
        NgbDatepickerModule,
        ColorPickerModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        NgbModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
